<template>
  <b-table
    ref="table"
    show-empty
    empty-text="There is no Live!"
    :items="getLiveList"
    :fields="columns"
    :current-page="pagination.currentPage"
    :per-page="pagination.perPage"
    aria-busy="true"
    class="position-relative"
    responsive="lg"
    primary-key="id"
    :no-provider-sorting="true"
  >
    <template #cell(session_link)="{item}">
      <b-link
        :href="item.session_link"
        target="_blank"
      >
        Go To Live
      </b-link>
    </template>
    <template #cell(actions)="{item}">
      <table-actions
        v-bind="{id:item.id,editModule:'edit-live',deleteRecord,deleteEndPoint}"
      />
    </template>
  </b-table>
</template>

<script>
import TableActions from '@/common/components/common/Table/TableActions.vue'
import deleteEntityComposition from '@/common/compositions/common/deleteRecord'

export default {
  name: 'LiveListTable',
  components: {
    TableActions,
  },
  props: {
    pagination: { type: Object, default: () => {} },
    getLiveList: { type: Function, default: () => null },
  },
  setup() {
    const { deleteRecord, table } = deleteEntityComposition()

    return {
      deleteRecord,
      table,
    }
  },
  data() {
    return {
      columns: [
        { key: 'activity_name', label: 'NAME', sortable: true },
        { key: 'activity_description', label: 'DESCRIPTION', sortable: true },
        { key: 'session_start', label: 'START DATE/TIME', sortable: true },
        { key: 'session_end', label: 'END DATE/TIME', sortable: true },
        { key: 'session_link', label: 'Session Link', sortable: true },
        { key: 'session_to_be_raised', label: 'To Be Raised', sortable: true },
        { key: 'entity_id.name', label: 'Entity Name', sortable: true },
        { key: 'actions' },
      ],
      deleteEndPoint: `${process.env.VUE_APP_ACTIVITIES_BASE_URL}/internalops/live/`,
    }
  },
}
</script>

<style scoped>

</style>
