<template>
  <b-col
    cols="12"
    class="text-right mb-1"
  >
    <b-button
      v-if="$can('store',resourceName)"
      :to="{ name: addRouteName }"
      variant="primary"
    >
      <span class="text-nowrap">Add</span>
    </b-button>
  </b-col>
</template>
<script>
export default {
  name: 'AddResource',
  props: {
    addRouteName: { type: String, default: '' },
    resourceName: { type: String, default: '' },
  },
}
</script>
<style lang="scss">

</style>
