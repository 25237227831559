import {
  ref,
} from '@vue/composition-api'
import axiosInstances from '@/libs/axios-instances'
import handleAlerts from './handleAlerts'
import store from '@/store'

export default function () {
  const {
    showErrors,
    successfulDeletionAlert,
    confirmDelete,
  } = handleAlerts()

  const table = ref(null)

  const deleteEntityRequest = (deleteEndPoint, id) => {
    axiosInstances.http.delete(`${deleteEndPoint}${id}`, {
      headers: {
        entityId: store.getters['mainEntity/getEntityId'],
      },
    }).then(() => {
      successfulDeletionAlert()
      table.value.refresh()
    }).catch(error => {
      showErrors(error.response.data.errors)
    })
  }
  const deleteRecord = (deleteEndPoint, id, message = null) => {
    confirmDelete(message).then(() => {
      deleteEntityRequest(deleteEndPoint, id)
    })
  }
  return {
    deleteRecord,
    table,
  }
}
