<template>
  <b-dropdown
    variant="link"
    no-caret
  >
    <template #button-content>
      <feather-icon
        icon="MoreVerticalIcon"
        size="16"
        class="align-middle text-body"
      />
    </template>
    <b-dropdown-item
      v-if="!!viewModule"
      :to="{ name: viewModule, params: { id} }"
    >
      <feather-icon icon="FileTextIcon" />
      <span class="align-middle ml-50"> View </span>
    </b-dropdown-item>

    <b-dropdown-item :to="{ name: editModule, params: { id}}">
      <feather-icon icon="EditIcon" />
      <span> Edit </span>
    </b-dropdown-item>

    <b-dropdown-item @click="deleteRecord(deleteEndPoint,id)">
      <feather-icon icon="TrashIcon" />
      <span
        class="align-middle ml-50"
      >Delete
      </span>
    </b-dropdown-item>

  </b-dropdown>
</template>

<script>

export default {
  name: 'TableActions',
  props: {
    id: { type: Number, default: null },
    viewModule: { type: String, default: '' },
    editModule: { type: String, default: '' },
    deleteEndPoint: { type: String, default: '' },
    deleteRecord: { type: Function, default: () => {} },
  },
}
</script>

<style scoped>

</style>
