<template>
  <div>

    <b-row>
      <add-resource
        resource-name="LiveController"
        add-route-name="add-live"
      />
      <b-col>
        <b-card header="Search">
          <b-row>
            <b-col md="5">
              <search
                placeholder="Name"
                :search-query.sync="searchQuery"
                @refresh="refreshTable"
              />
            </b-col>
            <b-col
              md="2"
              class="mt-1 mt-md-0"
            >
              <search-button @refresh="refreshTable" />
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <b-row align-h="center">
      <div
        v-if="loading"
        class="loader"
      />
    </b-row>

    <b-row>
      <b-col>
        <b-card>
          <live-list-table
            ref="parentTableComponent"
            :pagination="pagination"
            v-bind="{
              getLiveList
            }"
          />
          <pagination
            :total-rows="pagination.totalRows"
            :per-page="pagination.perPage"
            :current-page.sync="pagination.currentPage"
          />
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import AddResource from '@/common/components/common/Table/AddResource.vue'
import Search from '@/common/components/common/Table/Search.vue'
import SearchButton from '@/common/components/common/Table/SearchButton.vue'
import LiveListTable from '@/common/components/LiveManagement/LiveListTable.vue'
import Pagination from '@/common/components/common/Table/Pagination.vue'

export default {
  name: 'LiveList',
  components: {
    AddResource,
    Search,
    SearchButton,
    LiveListTable,
    Pagination,
  },
  data() {
    return {
      searchQuery: '',
      pagination: {
        currentPage: 1,
        perPage: 10,
        totalRows: 1,
      },
      loading: true,
    }
  },
  methods: {
    refreshTable() {
      this.$refs.parentTableComponent.$refs.table.refresh()
    },
    getLiveList() {
      const promise = this.$activities.get(`internalops/live/list/${this.pagination.currentPage}/${this.pagination.perPage}`, {
        params: {
          name: this.searchQuery,
        },
      })
      return promise.then(res => {
        const liveList = res.data.data.data
        this.pagination.perPage = parseInt(res.data.data.pagination.per_page, 10) // !because it returns as string from api, it should be corrected in api
        this.pagination.totalRows = res.data.data.pagination.total
        return liveList || []
      }).finally(() => { this.loading = false })
    },
  },
}

</script>

<style lang="scss">

</style>
